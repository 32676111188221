import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>i m teh greetst koder aliv</h1>
      <img src="./images/logo192.png" alt="bldsahdasjkdkjsjkfgdsjfgksj" />
    </div>
  );
}

export default App;
